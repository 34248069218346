<template>
    <div>
        <div class="search">
            <div>
                <div class="a-inline-block">
                    <label for="">关键字</label>
                    <a-input v-model="key" size="large" placeholder="请输入工作点编号、工作点名称、地址等关键字" />
                </div>
                <div class="a-inline-block">
                    <label for="">所属部门/单位</label>
                    <a-select v-model="onTeam" show-search option-filter-prop="children" class="ant-select-lg">
                        <a-select-option :value="item.did" v-for="(item,index) in teamList" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                </div>
                <div class="a-inline-block">
                    <label for="">负责人</label>
                    <a-input size="large" @focus="focus" :value="onStaff.name||''" @click="staffFlag=true" placeholder="请选择负责人" />
                    <!-- <a-select v-model="onStaff"  class="ant-select-lg">
                        <a-select-option  :value="item.userid" v-for="(item,index) in staff" :key="index">{{item.name}}</a-select-option>
                    </a-select> -->
                </div>
                <div class="a-inline-block">
                    <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
                    <a-button size="large" @click="reset">重置</a-button>
                </div>
            </div>
        </div>
        <div style="text-align:right;">
            <div class="a-inline-block" @click="annexModel=true">
                <img src="@/assets/imgs/tMenu4.png" alt="" /><label for="">导入</label>
            </div>
            <div class="a-inline-block" @click="excelout">
                <img src="@/assets/imgs/tMenu4.png" alt=""><label for="">导出</label>
            </div>
            <a-button class="addBtn" size="large" type="primary" @click="delAll">批量删除</a-button>

            <!-- <a-button class="addBtn" size="large" type="primary" @click="Add">添加服务人员</a-button> -->
            <a-button class="addBtn" size="large" type="primary" style="margin-left:15px" @click="selFollow">指派负责人</a-button>
        </div>
        <a-table :row-selection="rowSelection" :rowKey='record=>record.id' :columns="columns" :data-source="data" class="table" :pagination="pagination">
            <span slot="num" slot-scope="text, record, index">
                {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
            </span>
            <template slot="Status" slot-scope="record">
                <a href="javascript:;" @click="showStatus(record)"> {{record.status}}</a>
            </template>
            <template slot="Action" slot-scope="text,record">
                <!-- <a class="action" href="javascript:;" @click="showDetail(record.id)">查看</a> -->
                <a class="action" href="javascript:;" @click="Edit(record.id)">编辑</a>
                <a-popconfirm title="确定要删除吗?" @confirm="() => onDelete(record.id)">
                    <a class="action del" href="javascript:;">删除</a>
                </a-popconfirm>
            </template>
        </a-table>
        <a-modal v-model="visible" :centered="true" title="修改工作时间" @ok="editStatus">
            <div class="addBox">
                <div class="add_title">请选择结束工时</div>
                <a-date-picker v-model="endTime" :inputReadOnly="true" style="margin:30px 0" size="large" />
            </div>
        </a-modal>
        <a-modal v-model="showFollow" :centered="true" title="分配跟进人" @ok="setFollow">
            <div class="addBox">
                <div class="add_title" style="margin-bottom:30px">请选择跟进人</div>
                <a-select v-model="onStaff" class="ant-select-lg" style="width: 200px;">
                    <a-select-option :value="item.userid" v-for="(item,index) in staff" :key="index">{{item.name}}</a-select-option>
                </a-select>
            </div>
        </a-modal>
        <a-modal v-model="annexModel" :centered="true" :footer="null" title="导入">
            <div>
                请先下载模板，按格式修改后导入
                <a-button @click="downExl()">下载模板</a-button>
            </div>
            <div>
                <label for="annex" class="annexBtn">
                    <img src="@/assets/imgs/ico_daoru.png" alt="" />
                    <a-button @click="$refs.annexFile.click()">导入</a-button>
                </label>
                <input type="file" id="annex" style="display: none" ref="annexFile" @change="annexUpload($event)" />
            </div>
        </a-modal>
        <staff :staffFlag="staffFlag" @updateStaffFlag="updateStaffFlag"></staff>
    </div>
</template>
<script>
import Staff from "../../../components/staff.vue";
import { requestXml, ajaxUrl, fileUpload } from '../../../assets/js/request';
export default {
    components: {
        Staff,
    },
    data() {
        return {
            rowSelection: {
                onSelect: (record, selected, selectedRows) => {
                    console.log(record, selected, selectedRows);
                    this.selectedRows = selectedRows;
                },
                onSelectAll: (selected, selectedRows, changeRows) => {
                    console.log(selected, selectedRows, changeRows);
                    this.selectedRows = selectedRows;
                },
            },
            key: "",
            selSkillid: '', //搜索-技能
            selStatus: '', //搜索-技能
            columns: [
                {
                    title: '序号', dataIndex: 'num', width: 70, align: 'center',
                    scopedSlots: { customRender: 'num' },
                },
                // { title: '编号',align:'center', dataIndex: 'id', key: '11'},
                { title: '工作点名称', align: 'center', dataIndex: 'oname', key: '1' },
                { title: '工作点地址', align: 'center', dataIndex: 'address', key: '2' },
                { title: '所属部门', align: 'center', dataIndex: 'dname', key: '4' },
                { title: '负责人', align: 'center', dataIndex: 'sname', key: '12' },
                { title: '组长', align: 'center', dataIndex: 'wname', key: '4' },
                { title: '服务人员数', align: 'center', dataIndex: 'workernum', key: '5' },
                { title: '基数', align: 'center', dataIndex: 'base', key: '7' },
                {
                    title: '操作',
                    key: 'operation',
                    align: 'center',
                    fixed: 'right',
                    width: 250,
                    scopedSlots: { customRender: 'Action' },
                },
            ],
            sex: ['', '男', '女'],  //性别
            status: ['', '在职', '离职'],  //工作状态
            insurance: ['', '有', '无'],   // 有无工伤
            data: [],
            selectedRowKeys: [],
            selectedRows: '',
            // 分页
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
                pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                showTotal: total => `共 ${total} 条`, // 显示总数
                onChange: (current) => this.changePage(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize)
                },
            },
            onTemp: '',
            visible: false,
            endTime: '', //工作结束时间
            showFollow: false, //跟进人弹框
            teamList: '',
            onTeam: '',
            staff: '',
            staffFlag: false,
            onStaff: {
                name: "",
                userid: "",
            },
            skillid: '', //技能列表
            search: '',  //搜索条件
            annexModel: false,
            onSelFollow: false,
        };
    },
    
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('_operatList',JSON.stringify(this._data))
    },
    mounted(){
        if(this.fromPath == "/scrm_pc/worker/operatAdd") {
            var data=JSON.parse(sessionStorage.getItem('_operatList'))
            sessionStorage.removeItem('_operatList')
            for(let key in data){
                console.log(data[key],this[key])
                this[key] = data[key]
            }
        }
        this.getData();
        // this.getStaff();
        // this.getSkillid();
        this.getTeamList();
    },
    methods: {
        selFollow() {
            if (this.selectedRows.length < 1) {
                this.$message.info('请选择服务人员');
                return false;
            } else {
                this.onSelFollow = true;
                this.staffFlag = true;
            }
        },
        // 搜索
        onSearch() {
            this.pagination.current = 1;
            this.getData();
        },
        reset() {
            this.key = "";
            this.onTeam = null;
            this.onStaff.userid = '';
            this.onStaff.name = '';
            this.getData();
        },
        // 获取部门
        getTeamList() {
            requestXml("/jieb/Target/department", "POST", (res) => {
                this.teamList = res;
            });
        },
        // 负责人-输入框聚焦是清除其焦点
        focus(e) {
            e.srcElement.blur();
        },
        // 分配完
        updateStaffFlag(data) {
            console.log(data, "dasds")
            if (data != 1) {
                this.onStaff = data;
                if (this.onSelFollow) {
                    this.setFollow();
                }
            }
            this.staffFlag = false;
        },
        setFollow() {
            this.onSelFollow = false;
            let sel = this.selectedRows;
            let idArr = [];
            for (let i = 0; i < sel.length; i++) {
                idArr.push(sel[i].id);
            }
            requestXml("/jieb/Operating/editrespons", "POST", () => {
                this.onStaff.userid = '';
                this.onStaff.name = '';
                this.getData();
            }, { "ids": idArr, responsibility: this.onStaff.userid })
        },
        // 获取技能分类
        getSkillid() {
            requestXml("/jieb/Temporary/skillshow", "POST", (res) => {
                this.skillid = res.list;
                // console.log(this.skillid);
                // console.log(JSON.stringify( this.skillid));
                if (this.id) {
                    this.getDetail();
                }
                // console.log(this.skillid);
            }, { "page": { "curpage": 1, "pagesize": 999999 } })
        },
        // 下载模板
        downExl() {
            window.open(window.location.protocol + "//" + window.location.host + '/uploads/工作点信息导入.xls');
        },
        // 导入
        annexUpload(e) {
            fileUpload(e, (res) => {
                console.log(res);
                requestXml("/jieb/Operating/excelinput", "POST", (res) => {
                    console.log(res);
                    this.annexModel = false;
                    this.$refs.annexFile.value = ''
                    this.getData();
                }, { id: res.id })
            })
        },
        // 导出
        excelout() {
            window.open(ajaxUrl + "/jieb/Operating/excelout?search=" + JSON.stringify(this.search));
        },

        // 获取成员
        getStaff() {
            requestXml("/scrm/Staff/getMinList", "POST", (res) => {
                this.staff = res;
            })
        },
        // showStatus(res){
        //     this.onTemp=res;
        //     this.visible=true;
        // },
        // editStatus(){
        //      requestXml("/jieb/Temporary/status","POST",(res) => {
        //         console.log(res)
        //         this.getData();
        //     },{"id": this.onTemp.id,'time': Math.round(this.endTime/1000)})
        // },
        getData() {
            requestXml("/jieb/Operating/operatshow", "POST", (res) => {
                console.log(res);
                for (let i = 0; i < res.list.length; i++) {
                    // res.list[i].sex =  this.sex[Number(res.list[i].sex)];
                    // res.list[i].status = this.status[Number(res.list[i].status)];
                    // res.list[i].insurance = this.insurance[Number(res.list[i].insurance)];
                    // res.list[i].deadline = getDateTime(res.list[i].deadline);

                }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
                this.data = res.list;
                this.search = res.search;
            }, { key: this.key, department: this.onTeam, responsibility: this.onStaff.userid, "page": { "curpage": this.pagination.current, "pagesize": this.pagination.pageSize } })
        },
        onSelectChange(selectedRowKeys) {
            console.log('selectedRowKeys changed: ', selectedRowKeys);
            this.selectedRowKeys = selectedRowKeys;
        },
        // 分页
        changePage(current) {
            //current参数表示是点击当前的页码，
            console.log(current)
            this.pagination.current = current;
            this.getData(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            console.log(current, pageSize)
            this.pagination.current = current;
            this.pagination.pageSize = pageSize;
            this.getData(); //向后端发送请求
        },
        // 查看
        showDetail(id) {
            this.$router.push({
                path: "/scrm_pc/temp/tempDetail",
                query: {
                    id: id,
                }
            });
        },
        //添加工人
        Add() {
            this.$router.push({
                path: "/scrm_pc/worker/operatAdd",
            });
        },
        // 编辑
        Edit(id) {
            this.$router.push({
                path: "/scrm_pc/worker/operatAdd",
                query: {
                    id: id,
                }
            });
        },
        // 关闭
        // off(id){
        //     requestXml("/scrm/Task/setClose","POST",(res) => {
        //         console.log(res)
        //         this.getData();
        //     },{"id": id})
        // },
        // 删除
        onDelete(id) {
            requestXml("/jieb/Operating/operatdel", "POST", (res) => {
                console.log(res)
                this.getData();
            }, { "id": id })
        },
        delAll() {
            if (this.selectedRows.length < 1) {
                this.$message.info('请先选择');
                return false;
            } else {
                let idArr = [];
                let sel = this.selectedRows;
                for (let i = 0; i < sel.length; i++) {
                    idArr.push(sel[i].id);
                }
                this.onDelete(idArr);
            }
        },
    },

};
</script>
<style scoped>
.search {
    display: flex;
    justify-content: space-between;
}
.addBtn {
    width: auto;
}
.action {
    display: inline-block;
    padding: 5px 10px;
    margin: 0 5px;
    background: #dceeff;
    border: 1px solid #36a3ff;
    color: #36a3ff;
    border-radius: 5px;
}
.del {
    background: #ffdcdc;
    border-color: #ff4c4c;
    color: #ff4c4c;
}
.annexBtn {
    display: block;
    margin: 20px auto;
    width: 200px;
    text-align: center;
}
.annexBtn img {
    display: block;
    width: 100px;
    margin: 10px auto;
}
</style>

